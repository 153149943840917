import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import CallToAction from 'src/components/CallToAction'
import Hero from './sections/hero/_index'
import pageContext from './pageContext.json'
import ImageTarifaZero from '../../assets/images/temps/tarifa-zero.png'
import { PositionStickyDiv } from './style'
import documents from 'src/assets/data/tabela-tarifas/documentacao.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IFile {
  name: string;
  url: string;
}

interface IDocumentation {
  name: string;
  date?: string;
  month?: string;
  year?: string;
  description?: string;
  file: IFile;
}

interface IAccountDocumentation {
  accountType: string;
  documentation: IDocumentation[];
}

const Tarifas = () => {
  const documentsList: IAccountDocumentation[] = documents

  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-8'>
            <h3>Conta digital gratuita</h3>
            <img className='pb-3 ml-0 mr-auto' src={ImageTarifaZero} alt='Tarifa Zero' />
            <ul className='fs-17 pt-4'>
              <li>Abertura de conta </li>
              <li>Manutenção de conta</li>
              <li>Emissão de boleto</li>
              <li>Transferência entre contas Banco Inter</li>
              <li>Transferência para outros bancos</li>
              <li>Anuidade do cartão</li>
            </ul>
            <h4>Consulte as tabelas de tarifas dos serviços bancários</h4>
            <DocumentationList accountDocumentation={documentsList} />
          </div>
          <div className='col-12 col-md-6 col-lg-4'>
            <PositionStickyDiv>
              <CallToAction
                section='dobra_2'
                elementAction='click button'
                elementName='Abra sua conta'
                sectionName='Conta digital gratuita'
                redirectUrl='/pra-voce/conta-digital/pessoa-fisica/'
                text='Abra sua conta'
                link='/pra-voce/conta-digital/pessoa-fisica/'
              >
                <h3 className='fs-17 mb-2'>Conta digital gratuita</h3>
                <h4 className='fs-22 fw-400 mb-0'>Ø</h4>
              </CallToAction>
            </PositionStickyDiv>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const DocumentationList = ({ accountDocumentation }: { accountDocumentation: IAccountDocumentation[] }) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <div>
      {accountDocumentation.map((accountDoc: IAccountDocumentation, index: number) => (
        <div key={index}>
          <h5>Tarifas {accountDoc.accountType}</h5>
          <ul className='list-unstyled'>
            {accountDoc.documentation && accountDoc.documentation.map((doc: IDocumentation, docIndex: number) => (
              <li key={docIndex}>
                <a
                  href={doc.file.url}
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: `[PDF] Tarifas ${accountDoc.accountType} - Em vigor a partir de ${doc.date}`,
                      section_name: 'Conta digital gratuita',
                      redirect_url: doc.file.url,
                    })
                  }}
                >[PDF] Tarifas {accountDoc.accountType} - Em vigor a partir de {doc.date}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default Tarifas
